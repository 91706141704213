import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { WealthXLogoBlack, WealthXLogoWhite } from "../../assets/images";
import { useBackofficeURL, useIsTablet } from "../../hooks/common.hooks";
import "./navigation-bar.scss";

const navigationItem = [
  // { title: "Features", href: "/features" },
  { title: "Pricing", href: "/pricing" },
  { title: "Contact", href: "/contact" },
  {
    title: "Login",
    href: `${process.env.GATSBY_BACKOFFICE_PAGE}/login`,
  },
];

const handleNavigateSignupPage = (url: string) => {
  window.location.href = url;
};

const NavigationBar = ({ path }) => {
  const backOfficeURL = useBackofficeURL();
  const signupPage = `${backOfficeURL}/signup`;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpaque, setMenuOpaque] = useState(false);
  const [isInHomePage, setIsInHomePage] = useState(false);

  useEffect(() => {
    window.onload = () => {
      if (window?.location?.pathname === "/") setIsInHomePage(true);
    };
  }, []);
  const [isViewPortWithinFirstViewHeight, setIsViewPortWithinFirstViewHeight] =
    useState(true);
  const [shouldNavBarBeTransparent, setShouldNavBarBeTransparent] =
    useState(true);
  const [logoSrc, setLogoSrc] = useState(WealthXLogoWhite);

  const isTablet = useIsTablet();
  const wrapperRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (["/pricing/", "/contact/", "/signup/"].includes(path)) {
      setIsInHomePage(false);
    }
  }, [path]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside({ target }: MouseEvent): void {
      if (
        wrapperRef.current &&
        !wrapperRef?.current?.contains(target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    /**
     * Handle scroll event to change menu opacity when scrolling past the hero section
     */
    const handleScroll = () => {
      const pathSegments = window.location.pathname.split("/").filter(Boolean);
      if (["contact", "pricing", "signup"].includes(pathSegments[0])) return;
      if (
        typeof window !== "undefined" &&
        window.scrollY > window.innerHeight - 100
      ) {
        setIsViewPortWithinFirstViewHeight(false);
      } else {
        setIsViewPortWithinFirstViewHeight(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    setShouldNavBarBeTransparent(
      path === "/" && isViewPortWithinFirstViewHeight
    );
    if (isTablet) {
      setLogoSrc(WealthXLogoBlack);
    } else {
      setLogoSrc(
        path === "/" && isViewPortWithinFirstViewHeight
          ? WealthXLogoWhite
          : WealthXLogoBlack
      );
    }
  }, [path, isViewPortWithinFirstViewHeight, isTablet]);

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickMenuItem = (href: string) => {
    if (href === "/") setIsInHomePage(true);
    else setIsInHomePage(false);
    setIsMenuOpen(false);
    navigate(href);
  };
  return (
    <div className="navigation-bar" ref={wrapperRef}>
      <div
        className={classNames("navigation-bar__container", {
          opaque: !shouldNavBarBeTransparent,
        })}
      >
        <div
          className="navigation-bar__icon"
          onClick={() => {
            handleClickMenuItem("/");
          }}
        >
          <img src={logoSrc} alt="wealthx-logo" />
        </div>
        {!isTablet ? (
          <div className="navigation-bar__items">
            {navigationItem.map(({ title, href }) => (
              <div
                key={title}
                className="navigation-bar-items__item"
                onClick={() => {
                  handleClickMenuItem(href);
                }}
              >
                {title}
              </div>
            ))}
            <button
              type="button"
              className={classNames("navigation-bar__button", {
                opaque: !shouldNavBarBeTransparent,
              })}
              onClick={() => {
                setIsInHomePage(false);
                navigate("/signup");
              }}
            >
              Sign-up
            </button>
          </div>
        ) : (
          <div className="hamburger-button" onClick={handleOpenMenu}>
            <div className={isMenuOpen ? "open" : ""} id="nav-icon2">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        )}
      </div>
      <div
        ref={wrapperRef}
        className={classNames("navigation-bar__menu", { open: isMenuOpen })}
      >
        {navigationItem.map(({ title, href }) => (
          <div
            key={title}
            className="navigation-bar-items__item"
            onClick={() => {
              handleClickMenuItem(href);
            }}
          >
            {title}
          </div>
        ))}
        <button
          type="button"
          className="navigation-bar__button"
          onClick={() => {
            setIsInHomePage(false);
            navigate("/signup");
          }}
        >
          Sign-up
        </button>
      </div>
    </div>
  );
};

export default NavigationBar;
