import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { PageProps } from "gatsby";

import Footer from "../footer";
import NavigationBar from "../navigation-bar";
import "./layout.scss";
import "../../assets/scss/variables.css";
import FavIcon from "../../favicon.svg";
import {
  useApolloId,
  useGa4Id,
  useGtmId,
  useLeadFeederId,
  useMetaPixelId,
} from "../../hooks/common.hooks";

interface GTMProps {
  gtmId: string;
}

const GTM: React.FC<GTMProps> = ({ gtmId }) => {
  useEffect(() => {
    ((w, d, s, l, i) => {
      // eslint-disable-next-line no-param-reassign
      (w as any)[l] = (w as any)[l] || [];
      (w as any)[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s) as HTMLScriptElement;
      const dl = l !== "dataLayer" ? `&l=${l}` : "";
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      if (f.parentNode) {
        f.parentNode.insertBefore(j, f);
      }
    })(window, document, "script", "dataLayer", gtmId);
  }, [gtmId]);

  return (
    <Helmet>
      <noscript>
        {`
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="gtm"
          />
        `}
      </noscript>
    </Helmet>
  );
};

interface MetaPixelProps {
  pixelId: string;
}

const MetaPixel: React.FC<MetaPixelProps> = ({ pixelId }) => (
  <Helmet>
    <script>
      {`
          !((f, b, e, v, n, t, s) => {
            if (f.fbq) {
              return;
            }
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          );
          fbq("init", "${pixelId}}");
          fbq("track", "PageView");
        `}
    </script>
    <noscript>
      {`
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"
            alt="pixel"
          />
        `}
    </noscript>
  </Helmet>
);

const GA4: React.FC<{ ga4Id: string }> = ({ ga4Id }) => (
  <Helmet>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${ga4Id}`}
    />
    <script>
      {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${ga4Id}');`}
    </script>
  </Helmet>
);

const Apollo: React.FC<{ apolloId: string }> = ({ apolloId }) => (
  <Helmet>
    <script>
      {`function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script");
        o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0,
        o.onload=function(){window.trackingFunctions.onLoad({appId:"${apolloId}"})},
        document.head.appendChild(o)}initApollo();`}
    </script>
  </Helmet>
);

const LeadFeeder: React.FC<{ leadFeederId: string }> = ({ leadFeederId }) => (
  <Helmet>
    <script>
      {`(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('${leadFeederId}');`}
    </script>
  </Helmet>
);

const Layout = ({ children, ...rest }: PageProps) => {
  const { path } = rest;
  const gtmId = useGtmId();
  const metaPixelId = useMetaPixelId();
  const ga4Id = useGa4Id();
  const apolloId = useApolloId();
  const leadFeederId = useLeadFeederId();
  return (
    <>
      <GA4 ga4Id={ga4Id} />
      <Apollo apolloId={apolloId} />
      <LeadFeeder leadFeederId={leadFeederId} />
      <GTM gtmId={gtmId} />
      <MetaPixel pixelId={metaPixelId} />
      <Helmet
        defaultTitle="WealthX"
        link={[{ rel: "shortcut icon", type: "image/png", href: `${FavIcon}` }]}
        meta={[{ name: "format-detection", content: "telephone=no" }]}
      />
      <NavigationBar path={path} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
