import { useState, useEffect } from "react";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

export const useIsMobile = () => useMediaQuery("(max-width: 600px)");
export const useIsTablet = () => useMediaQuery("(max-width: 1023px)");

export const useBackofficeURL = () =>
  process.env.GATSBY_BACKOFFICE_PAGE || "https://backoffice.wealthx.au";

export const useDemoPageURL = () =>
  process.env.GATSBY_DEMO_PAGE || "https://demo.app.wealthx.au";

export const useGtmId = () => process.env.GATSBY_GTM_ID || "";

export const useMetaPixelId = () => process.env.GATSBY_PIXEL_ID || "";

export const useGa4Id = () => process.env.GATSBY_GA4_ID || "";

export const useApolloId = () => process.env.GATSBY_APOLLO_ID || "";

export const useLeadFeederId = () => process.env.GATSBY_LEADFEEDER_ID || "";
